@import url(https://fonts.googleapis.com/css?family=Poppins:regular,bold);
body {
  margin: 0;
  font-family: 'Poppins', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

*,*:focus,*:hover{
  outline:none;
}
